import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
import './App.css';
import SidebarForm from './SidebarForm';
import ContentChart from './ContentChart';
import { AppProvider } from './AppContext';
import Typography from '@material-ui/core/Typography';
import { api } from './api';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  app_header: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  formSidebar: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  content: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const colorScheme = [
  "#25CCF7", "#FD7272", "#54a0ff", "#00d2d3",
  "#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e",
  "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50",
  "#f1c40f", "#e67e22", "#e74c3c", "#ecf0f1", "#95a5a6",
  "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d",
  "#55efc4", "#81ecec", "#74b9ff", "#a29bfe", "#dfe6e9",
  "#00b894", "#00cec9", "#0984e3", "#6c5ce7", "#ffeaa7",
  "#fab1a0", "#ff7675", "#fd79a8", "#fdcb6e", "#e17055",
  "#d63031", "#feca57", "#5f27cd", "#54a0ff", "#01a3a4"
]

function App() {

  const classes = useStyles();

  const [conversion, setConversion] = useState({});

  function handleConvertUnits(msg, setIsRequesting) {
    console.log(msg)
    api.post("/calculate", msg).then((response) => {
      setConversion(response.data)
      console.log(response.data)
      setIsRequesting(false)
    })
    .catch((error) => {
      console.log(error);
      setIsRequesting(false);
    }
    );
  }

  function handleClear() {
    setConversion({});
  }


  return (

    <div className="App">
      <header className={classes.app_header}>
        <Typography variant="h3" component="h3">
          Units converter
        </Typography>
      </header>
      <AppProvider>
        <div className={classes.root}>
          <Grid  container spacing="1" direction="row" >
            <Grid item xs={0} md={2}></Grid>
            <Grid item xs={10} md={8}>
              <SidebarForm convert={handleConvertUnits} clear={handleClear} />
            </Grid>
            <Grid item xs={0} md={2}></Grid>
          </Grid>
          <Grid  container spacing="1" direction="row" >
            <Grid item xs={0} md={2}></Grid>
            <Grid item xs={10} md={8}>
              <ContentChart conversion={conversion} />
            </Grid>
            <Grid item xs={0} md={2}></Grid>
          </Grid>

        </div>
      </AppProvider>
    </div>
  );
}

export default App;
