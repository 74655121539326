import React, { createContext, useState } from "react";

const AppContext = createContext();

// This context provider is passed to any component requiring the context
export const AppProvider = ({ children }) => {

  const [isRequesting, setIsRequesting] = useState(false);  
  
  return (
    <AppContext.Provider
    value={{ 
      isRequestingObj: [isRequesting, setIsRequesting]
     }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;

