import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND || "http://localhost:5000",
  headers: { 
    "Content-Type": 'application/json',
  },
});


