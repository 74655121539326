import React, { useState, useRef, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { TextField, Button, Grid } from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import AppContext from './AppContext'
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formSidebar: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  actionButtons: {    
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  button: {    
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));

function SidebarForm(props) {

  const radionuclides = ["U-238", "U-235", "U-234", "Th-230", "Th-232", "Ra-226", "Ra-228", "Pb-210", "Po-210", "Pu-238", "Pu-239", "Pu-240", "Cs-137", "Sr-90", "Co-60", "Am-242", "C-14", "Eu-152", "Tc-99", "Pa-231"].sort();
  const databases = ["ICRP", "Euratom", "Kaeri", "NEA", "Nucleonica", "SKB"]
  const units = ["Bq", "Ci", "mol", "g"]

  const classes = useStyles();

  const appContext = useContext(AppContext)
  const { isRequestingObj } = appContext
  const [isRequesting, setIsRequesting] = isRequestingObj

  const { control, handleSubmit, formState: { errors } } = useForm();

  const [inputValue, setInputValue] = useState(8.4e5);
  const [inputUnits, setInputUnits] = useState(units[0]);
  const [outputUnits, setOutputUnits] = useState(units[0]);
  const [selectedDatabase, setSelectedDatabase] = useState(databases[0]);
  const [radionuclide, setRadionuclide] = useState(radionuclides[0]);

  const onError = (errors, e) => { 
    console.log("Error here!")
    console.log(errors, e)

  }

  const handleConvertUnits = async () => {

    setIsRequesting(true)
    // console.log("Selected data:")
    // console.log(radionuclide, selectedDatabase, inputUnits, outputUnits, inputValue)

    const inputData = {
      "RN": radionuclide,
      "database": selectedDatabase,
      "from_units": inputUnits,
      "to_units": outputUnits,
      "input": inputValue
    }

    props.convert(inputData, setIsRequesting)

  };

  const handleClear = () => {
    props.clear()
  };

  
  const handleChange = (event) => {
    console.log(event.target.value)
    setInputValue(parseFloat(event.target.value));
  };

  return (
    <Paper className={classes.formSidebar} >
      <Typography variant="h5" component="h5">
        Input Data
      </Typography>
      <form className={classes.root} noValidate autoComplete="off">
        <Autocomplete
          id="radionuclides_list"
          options={radionuclides}
          getOptionLabel={(option) => option}
          value={radionuclide}
          onChange={(event, newValue) => {
            setRadionuclide(newValue);
          }}
          disableClearable={true}
          renderInput={(params) =>
            <TextField {...params}
              label="Radionuclide"
              variant="outlined"
              margin="normal" />}
        />
        <Grid 
          container
          direction="row"
          justify="center"
          alignitems="stretch"
          spacing={1}>
            <Grid
              item
              xs={9}>
              <Typography variant="subtitle1" component="subtitle1">
                From
              </Typography>
              <Grid 
                container
                direction="row"
                justify="center"
                alignitems="stretch"
                spacing={1}>
                  <Grid
                    item
                    xs={8}>
                      <Controller
                        name="inputValue"
                        control={control}
                        rules={{ 
                          required: "required" ,
                          min: 0
                        }}
                        render={({ field }) => 
                        <TextField {...field} 
                          fullWidth
                          type="number"
                          variant="outlined"
                          margin="normal"
                          id="originalValue"
                          name="originalValue"
                          label="Value"
                          onChange={e => handleChange(e)}
                          value={inputValue}
                          helperText={                
                            (errors.originalValue ? errors.originalValue.message: "") +
                            ((errors.originalValue && errors.originalValue.type === "min") ?  "Min value allowed: 0": "")            
                          }
                          error={errors.originalValue? true : false}
                        />}
                      />
                  </Grid>
                  <Grid
                    item
                    xs={4}>
                    <Autocomplete
                    id="inputUnits_list"
                    options={units}
                    getOptionLabel={(option) => option}
                    value={inputUnits}
                    onChange={(event, newValue) => {
                      setInputUnits(newValue);
                    }}
                    disableClearable={true}
                    renderInput={(params) =>
                      <TextField {...params}
                        label="Units"
                        variant="outlined"
                        margin="normal" />}
                  />
                  </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={3}>
                <Typography variant="subtitle1" component="subtitle1">
                  To
                </Typography>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justify="center"
                  alignitems="center"
                  spacing={1}>
                    <Grid item xs={12}>
                      <Autocomplete
                        id="outputUnits_list"
                        options={units}
                        getOptionLabel={(option) => option}
                        value={outputUnits}
                        onChange={(event, newValue) => {
                          setOutputUnits(newValue);
                        }}
                        disableClearable={true}
                        renderInput={(params) =>
                          <TextField {...params}
                            label="Units"
                            variant="outlined"
                            margin="normal" />}
                      />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        
        <div className={classes.actionButtons}>
          
          <Button 
            onClick={handleConvertUnits}
            color="primary"
            variant="outlined">
            Convert
          </Button>
        
        </div>

        
      </form>
    </Paper>
  );
}

export default SidebarForm;
