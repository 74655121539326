import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppContext from './AppContext'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, TextField } from '@material-ui/core';

import './App.css';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function ContentChart(props) {

  const classes = useStyles();

  const appContext = useContext(AppContext)
  const { isRequestingObj } = appContext
  const [isRequesting, setIsRequesting] = isRequestingObj
  
  // TODO: use comments from props if present, which means some error occurred

  return (
    <Paper className={classes.content}>
      <Typography variant="h5" component="h5">
        Results
      </Typography>
      {isRequesting &&
        <div>
          <CircularProgress />
          <Typography variant="h6" component="h6">
            converting...
          </Typography>
        </div>
      }
      {props.conversion.result &&
        <Grid 
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={1}>
          <Grid item xs={9}>
            <TextField
              fullWidth
              id="result_field"
              label="Result"
              variant="outlined"
              margin="normal"
              value={props.conversion.result} />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="result_field"
              label="Units"
              variant="outlined"
              margin="normal"
              value={props.conversion.units} />
          </Grid>
        </Grid>
      }
    </Paper>
  );
}

export default ContentChart;
